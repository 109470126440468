"use client"

import {
  Filters,
  ParsedBrowsingState,
  RatingFilterQuery,
  SortQuery,
} from "@store-platform/types"
import {
  parseBrowsingState,
  parseFiltersFromSegments,
  parseSegmentsFromString,
} from "@store-platform/utils"
import { useParams, useSearchParams } from "next/navigation"
import { create } from "zustand"
import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

type StoreActions = {
  set: (state: ParsedBrowsingState) => void
}

const useClientBrowseStateStore = create<ParsedBrowsingState & StoreActions>(
  (set) => ({
    filters: undefined,
    searchQuery: undefined,
    attributes: undefined,
    set: (state: ParsedBrowsingState) => set(state),
  }),
)

export const useClientBrowseState = (): ParsedBrowsingState => {
  const params = useParams<{ segments?: string; query?: string }>()
  const searchParams = useSearchParams()
  const store = useClientBrowseStateStore()
  const setStore = useClientBrowseStateStore(useShallow((state) => state.set))

  useEffect(() => {
    if (params.query) {
      console.log("params?.query", params?.query)
      const filters: Filters = {}
      for (const [key, value] of searchParams.entries()) {
        if (key === "sort_by") {
          filters.sort_by = value as SortQuery
        } else if (key === "min_rating") {
          filters.min_rating = value as RatingFilterQuery
        } else if (key === "attribute") {
          if (!filters.attribute) filters.attribute = []
          filters.attribute?.push(value)
        }
      }
      const browsingState = parseBrowsingState(filters)
      browsingState.searchQuery = params.query
      setStore(browsingState)
    } else {
      console.log("params?.segments", params?.segments)
      const segments = parseSegmentsFromString(params?.segments ?? "")
      const browsingState = {
        ...parseBrowsingState(parseFiltersFromSegments(segments ?? [])),
        searchQuery: undefined,
      }
      console.log("browsingState", browsingState)
      setStore(browsingState)
    }
  }, [params.query, params?.segments, searchParams, setStore])

  return {
    filters: store.filters,
    searchQuery: store.searchQuery,
    attributes: store.attributes,
  }
}
